import React from 'react'
import { Section, Title1, HStack, Image, VStack, Body, Headline, Icon, Space, Media, Container } from '@themeable/react'
import { Logo } from '../../Patterns/Logo'

function Hero() {
  return (
    <Section>
      <Container >
        <HStack group align='middle'>
          <Icon name='Heart' color='primary' />
          <Headline tag='h1'>Now offering both in-person (South Orange County) and virtual doula support!</Headline>
        </HStack>
      </Container>
      <HStack sectionSpacing gridExpanded={[1, 1]} gridMedium={[1, 1]} gridCompact={[1]}>
        <VStack style={{ position: 'relative', minHeight: '300px' }} align='center middle'>
          <VStack group showOn='medium expanded'>
            <Title1 align='center' style={{ position: 'relative', zIndex: '2' }}>
              The <Title1 tag='span' color='primary'>"Why"</Title1> Behind my Birth Doula Practice
            </Title1>
          </VStack>
          <VStack>
            <VStack group showOn='compact'>
              <Title1 align='center' style={{ position: 'relative', zIndex: '2' }}>
                The <Title1 tag='span' color='primary'>"Why"</Title1> Behind my Birth Doula Practice
              </Title1>
            </VStack>
          </VStack>
          <span style={{
            opacity: '.1',
            position: 'absolute',
            width: '110%',
            height: '120%',
          }}>
            <Icon
              name='PatternFlower'
              size='100%'
              color='accent'
            />
          </span>
        </VStack>
        <VStack>
          <Body>
            I found my way into the doula world after 7 years of working with babies, kids and families in the hospital setting. I absolutely loved my career as a pediatric nurse. It was so fulfilling and life-giving to be able to care for babies and parents in their time of need. But I found my true passion after I had my first son.
          </Body>
          <Body>
            My pregnancy, birth, and postpartum were unexpectedly challenging. After a difficult pregnancy, 4th degree tear, and breastfeeding troubles, I knew I had to do things differently the second time around.
          </Body>
          <Body>
          </Body>
          <Body>
            Fast forward 2 years later and I had the pregnancy, birth, and postpartum of my DREAMS! It was all thanks to an amazing team of women around me and some major education. Now I’m here to pass on everything I’ve learned as a pediatric nurse and a mom to you, my fellow mama.
          </Body>
          <Body>
            I cannot wait to empower you, encourage you, and care for you!
          </Body>
        </VStack>
      </HStack>

      <Space.NewSection />
    </Section>
  )
}

export default Hero

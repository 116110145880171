import React, { useState, useEffect } from 'react'
import { collection, addDoc, Timestamp, arrayUnion, updateDoc, doc, addField } from "firebase/firestore";
import { db } from '../services/firebase';

import { Section, Icon, VStack, Title2, Body, Space, HStack, Button, Footnote, Subhead, Container, Label, Input } from '@themeable/react'

function SubscribeBanner() {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [formSuccess, setFormSuccess] = useState(false)

  const sendSubscribeInfo = () => {

    const isValidName = (name) => name && /^[a-zA-Z-' ]+$/.test(name);

    // Email validation using a regular expression
    const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    if (!isValidName(firstName)) {
      console.error('Invalid first name');
      setErrorMessage('Invalid first name');
      return;
    }

    if (!isValidName(lastName)) {
      console.error('Invalid last name');
      setErrorMessage('Invalid last name');
      return;
    }

    if (!isValidEmail(email)) {
      console.error('Invalid email');
      setErrorMessage('Invalid email');
      return;
    }

    const encodedEmail = email.replace(/\./g, ',');

    updateDoc(doc(db, 'subscriptions', 'generic'), {
      [encodedEmail]: {
        firstName: firstName,
        lastName: lastName,
        status: 'active',
        lastUpdated: Timestamp.now(),
        source: window.location.href
      }
    })
      .then(() => {
        setFormSuccess(true);
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };



  return (
    <>
      <VStack
        style={{
          background: 'rgb(var(--color-background-flat))', zIndex: '3', position: 'relative',
        }}
      >
        <Section>
          <HStack gridExpanded={[1, 1]} gridCompact={[1]} gridMedium={[1, 1]} layout='middle'>

            {/* Left column */}
            <VStack>
              <Title2>
                Wanna Become a Certified Mom?
              </Title2>
              <Body>
                I'll send you my best tips and tricks to help you achieve certified mom status.
              </Body>
              <Space.Content />
              <Space.Content />
              {formSuccess ? (<>
                <Subhead>
                  Thanks for subscribing!
                </Subhead>
              </>) : (<>
                <HStack>
                  <Label label='First name'>
                    <Container>
                      <Input onChange={((e) => { setFirstName(e) })} />
                    </Container>
                  </Label>
                  <Label label='Last name'>
                    <Container>
                      <Input onChange={((e) => { setLastName(e) })} />
                    </Container>
                  </Label>
                </HStack>
                <Label label='Email'>
                  <Container>
                    <Input onChange={((e) => { setEmail(e) })} />
                  </Container>
                </Label>
                {errorMessage && (
                  <>
                    <Footnote color='negative'>
                      {errorMessage}
                    </Footnote>
                  </>
                )}
                <HStack>
                  <Button title='Subscribe' variant='primary' onClick={sendSubscribeInfo} />
                </HStack>
              </>)}

            </VStack>

            {/* Right column (image) */}
            <VStack align='right bottom' showOn='medium expanded' style={{
              marginTop: '-80px',
              marginBottom: 'calc(var(--space-v-section) * -1)',
            }}>
              <VStack height='hug' width='hug'>
                <img src='../assets/jessicakounter-nurse-redeeming-special-needs.png'
                  style={{ width: '300px' }}
                />
              </VStack>
            </VStack>

          </HStack>
        </Section>
      </VStack>
    </>
  )
}

export { SubscribeBanner }

import React, { useEffect } from 'react';
import { Container, Headline, Icon, Subhead } from '@themeable/react-components'
import Hero from './Hero'
import BirthDoulaServices from './BirthDoulaServices'
import ALaCarte from './ALaCarte'

import Outro from './Outro'

import { Divider, Section, HStack, VStack, Title3, Body, Space } from '@themeable/react-components'
import Certifications from './Certifications';


function DoulaIndex({ setGlobals }) {

  useEffect(() => {
    setGlobals((prev) => ({
      ...prev,
      page: 'services',
      pageColor: '--color-background-flat',
      pageTitle: 'Postpartum Doula | Southern Orange County',
      pageDescription: "If you’re in south orange county looking for doula support during pregnancy, birth, postpartum, and breastfeeding, you can learn more! I would LOVE to be on your team!"
    }));
  }, [])




  return (<>

    <Hero />

    <Certifications />

    <BirthDoulaServices />

    <ALaCarte />



    <Outro setGlobals={setGlobals} />

    <Space.NewSection />

  </>)
}

export default DoulaIndex

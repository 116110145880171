// rfce
import React, { useEffect, useState } from 'react'
import { Headline, Footnote, Media, Body, HStack, Title2, Title3, Title1, Icon, Section, Space, VStack, Container, Button, Caption1 } from '@themeable/react'
import { Logo } from '../../Patterns/Logo'
import { Tag, Divider, Cell } from '@themeable/react-components';
import { Quote } from './Quote'
import { SubscribeBanner } from '../SubscribeBanner';
import MeetTheFam from './MeetTheFam';
import { useNavigate } from 'react-router-dom';
import './Home.css'


function Home({ globals, setGlobals }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setGlobals((prev) => ({
      ...prev,
      page: 'home',
      pageColor: '--color-background-elevated'
    }));
  }, [])

  useEffect(() => {
    fetch('../api/all-blogs')
      .then((res) => res.json())
      .then((res) => {
        setGlobals((prev) => ({
          ...prev,
          blogs: res
        }));
        setLoading(false);
      })
      .then((res) => {
        console.log(globals.blogs)
      })
      .catch((error) => { console.error('Error fetching data: ', error); });
  },
    []);

  const siteCategories = [
    {
      url: 'Pregnancy',
      icon: 'Pregnancy',
      name: 'Pregnancy'
    },
    {
      url: 'Labor%20and%20Delivery',
      icon: 'LaborDelivery',
      name: 'Labor and Delivery'
    },
    {
      url: 'Postpartum',
      icon: 'Postpartum',
      name: 'Postpartum'
    },
    {
      url: 'Breastfeeding',
      icon: 'Breastfeeding',
      name: 'Breastfeeding'
    },
    {
      url: 'Baby',
      icon: 'Baby',
      name: 'Baby'
    },
    {
      url: 'Mom',
      icon: 'Mom',
      name: 'Mom'
    },
  ]


  return (
    <>

      <VStack className='dark' style={{
        backgroundImage: 'url("https://res.cloudinary.com/create-new-entry/image/upload/v1590846869/certifiedmom/certified-mom-hello-hero.gif")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundSize: 'cover',
      }}>
        <VStack style={{ background: 'rgba(0,0,0,.6)' }}>
          <Section>
            <HStack
              gridExpanded={[1, 1]}
              gridMedium={[1, 1]}
              gridCompact={[1]}
              style={{ zIndex: '2' }}>
              <VStack>
                <Space.NewSection />
                <Logo width={'312'} />
                <Body>
                  My name is Jessica and I’m a Certified Pediatric Nurse, Birth and Postpartum Doula serving South Orange County, CA. I’m also a mom to 2 of the cutest boys! I have a thing for encouraging, equipping, and educating new moms.
                </Body>
                <Space.Content />
                <Button
                  title='Doula Services'
                  variant='accent'
                  iconTrailing='ArrowRight'
                  onClick={() => { navigate('../doula') }}
                  width='hug'
                />
                <Space.NewSection />
              </VStack>
            </HStack>
          </Section>
        </VStack>
      </VStack>



      <VStack style={{ background: 'rgb(var(--color-background-flat))' }}>
        <Section>
          <HStack gridExpanded={[1, 1]} gridCompact={[1]} gridMedium={[1, 1]} align='middle'>
            <HStack>
              <VStack>
                <Space.NewSection />
                <Divider />
                <Title2 color='accent'>Consider This the Online HQ for all Things Mom and Baby.</Title2>
                <VStack showOn='medium expanded'>
                  <Divider />
                </VStack>
                <Space.NewSection showOn='medium expanded' />
              </VStack>
              <Space.NewSection />

            </HStack>
            <VStack>
              <Space.NewSection showOn='compact' />
              <Space.NewSection showOn='compact' />

              <div className='custom-container'>
                <Container style={{ background: 'rgb(var(--color-primary))' }}>
                  <Section>
                    <Body color='on-primary'>
                      I pulled together everything I’ve learned on the job as a pediatric nurse and six years of motherhood to create a special space for you. Instead of going to google, you can search my site for all of the questions you feel silly for looking up. There’s no shame or judgment here, only tons of practical tips and tricks.
                    </Body>
                    <Body color='on-primary'>
                      I also added in a dash of encouragement for you mama, because you are SO deserving of being uplifted. Your job is beautiful, wonderful, messy, and also freaking hard. So take a breath, like a real inhale and exhale for 5 seconds breath, and then get your questions answered here.
                    </Body>
                  </Section>
                </Container>
              </div>
              <Space.NewSection showOn='compact' />
              <Space.NewSection showOn='compact' />
              <Space.NewSection showOn='compact' />
              <Space.NewSection showOn='compact' />
              <Space.NewSection showOn='compact' />
              <Space.NewSection showOn='compact' />
            </VStack>
          </HStack>

        </Section>
      </VStack>



      <Space.NewSection />
      <Section>
        <Space.NewSection showOn='compact' />
        <Space.NewSection showOn='compact' />
        <Space.NewSection showOn='compact' />
        <Space.NewSection showOn='compact' />
        <Title2 align='center' color='accent'>Give Me the Goods On:</Title2>
        <HStack gridExpanded={[1, 1, 1, 1, 1, 1]} gridCompact={[1, 1]} gridMedium={[1, 1, 1]} align='top'>
          {siteCategories.map((category) => (
            <VStack group align='center top'>
              <Button key={category.url} onClick={() => navigate(`../blog?tag=${category.url}`)}>
                <Container variant='fill' style={{ padding: '8px' }}>
                  <VStack align='center middle'>
                    <Icon
                      name={category.icon}
                      size='120px'
                      color='primary'
                    />
                  </VStack>
                </Container>
                <Space.Content />
                <Headline align='center' color='primary'>{category.name}</Headline>
              </Button>
            </VStack>
          ))}
        </HStack>
      </Section>


      <Section>
        <Space.NewSection showOn='compact' />
        <Space.NewSection showOn='compact' />
        <Space.NewSection showOn='compact' />
        <HStack gridExpanded={[1, 1]} gridCompact={[1]} gridMedium={[1, 1]} align='top'>
          <HStack>

            <Space.NewSection showOn='medium expanded' />
            <VStack showOn='medium expanded'>
              <Title2 color='accent'>
                My Top 3<br />Fave Blog Posts:
              </Title2>
            </VStack>
            <VStack showOn='compact'>
              <Title2 align='center' color='accent'>
                My Top 3<br />Fave Blog Posts:
              </Title2>
            </VStack>
            <Space.NewSection showOn='medium expanded' />
          </HStack>

          <VStack align='top'>

            {globals.blogs?.live?.slice(0, 3).map((blog, index) => (
              <>
                <Button variant='quaternary' url={`../blog/${blog.properties.URL.url}`}>
                  <HStack>
                    <VStack group>
                      <HStack>
                        {blog.properties.Tags.multi_select.map((tag, index) => (
                          <Tag status='neutral' text1={tag.name} />
                        ))}
                      </HStack>

                      <Headline color='primary'>{blog.properties.Name.title[0]?.plain_text}</Headline>
                      <Footnote color='text-secondary'>
                        {blog.properties.Description?.rich_text[0].plain_text}...
                      </Footnote>
                    </VStack>
                    <Media size='medium' imageSrc={blog.properties.Cover.files[0]?.file.url} aspectRatio='4-3' />
                  </HStack>
                </Button>
              </>
            ))}

            <Space.Content />

            <Container variant='fill'>
              <Cell variant='link' url='blog'>
                <HStack>
                  <Headline color='primary'>View all</Headline>
                  <Icon
                    name='ChevronRight'
                    color='primary'
                  />
                </HStack>
              </Cell>
            </Container>
          </VStack>
        </HStack>
      </Section>


      <VStack style={{ position: 'relative' }} showOn='expanded'>
        <VStack style={{ position: 'absolute', opacity: '.6', transform: 'translate(24px, -50%) rotate(15deg)' }}>
          <Icon
            name='Heart'
            size='300px'
            color='accent'
          />
        </VStack>
      </VStack>


      <Space.NewSection />
      <Space.NewSection showOn='compact' />
      <Space.NewSection showOn='compact' />
      <Space.NewSection showOn='compact' />
      <Quote />
      <Space.NewSection showOn='compact' />
      <Space.NewSection showOn='compact' />
      <Space.NewSection showOn='compact' />
      <Space.NewSection showOn='compact' />

      <Space.NewSection />
      <Space.NewSection />
      <SubscribeBanner />
      <MeetTheFam />
    </>
  )
}

export default Home;

import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet'

// Pages
import Home from './Pages/Home/Home';
import Blog from './Pages/Blog/Index';
import BlogEntry from './Pages/BlogEntry';
import MerchHome from './Pages/MerchHome';
import Contract from './Pages/Contract';

import DadasArm from './Pages/DadasArm';
import JesDigest from './Pages/JesDigest';
import NotFoundPage from './Pages/NotFoundPage';

// Components
import Navbar from './Patterns/Navbar';
import GlobalFooter from './Patterns/GlobalFooter'
import { VStack } from '@themeable/react-components';
import About from './Pages/About/About';
import MerchItem from './Pages/MerchItem';
import DoulaIndex from './Pages/Doula/Index';
import { ThemeableProvider } from '@themeable/react';
import ScrollToTop from './utils/ScrollToTop';

function App() {
  const [globals, setGlobals] = useState({
    blogs: null,
    mode: 'this is a test',
    page: null,
    navHeight: '60px',
    pageColor: "--color-background-flat", // Default color
    sayHelloSheet: false,
    pageTitle: 'Certified Mom',
    pageDescription: 'The Online HQ for all Things Mom and Baby.'
  });

  function BlogRedirect() {
    const { param } = useParams();
    return <Navigate to={`/blog/${param}`} />;
  }

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={globals.pageColor} />
        <title>{globals.pageTitle}</title>
        <meta name="description" content={globals.pageDescription} />
        <meta property="og:title" content={globals.pageTitle} />
        <meta property='og:description' content={globals.pageDescription} />
      </Helmet>
      <ThemeableProvider
        systemId='WYJ7WYZxiOLQfJEubK4S'
        svgPath='../icons_new.svg'
      >
        <VStack
          style={{
            overflowX: 'none',
            minHeight: '100vh',
            width: '100%',
            background: `rgb(var(${globals.pageColor}))`
          }}
        >
          <Router>
            <ScrollToTop />
            <Navbar globals={globals} setGlobals={setGlobals} />
            <Routes>
              <Route
                path='/'
                element={<Home globals={globals} setGlobals={setGlobals} />}
              />
              <Route
                path='/blog'
                element={<Blog globals={globals} setGlobals={setGlobals} />}
              />
              <Route
                path='/blog/:param'
                element={<BlogEntry globals={globals} setGlobals={setGlobals} />}
              />
              <Route
                path='/about'
                element={<About setGlobals={setGlobals} />}
              />
              <Route
                path='/contract'
                element={<Contract setGlobals={setGlobals} />}
              />
              <Route
                path='/shop'
                element={<MerchHome setGlobals={setGlobals} />}
              />
              <Route
                path='/shop/:param'
                element={<MerchItem setGlobals={setGlobals} />}
              />

              <Route path='/baby/:param' element={<BlogRedirect />} />
              <Route path='/pregnancy/:param' element={<BlogRedirect />} />
              <Route path='/postpartum/:param' element={<BlogRedirect />} />
              <Route path='/breastfeeding/:param' element={<BlogRedirect />} />
              <Route path='/mom/:param' element={<BlogRedirect />} />
              <Route path='/labor-and-delivery/:param' element={<BlogRedirect />} />

              <Route
                path='/doula'
                element={<DoulaIndex setGlobals={setGlobals} />}
              />
              <Route
                path='*'
                element={<NotFoundPage />}
              />
            </Routes>
            <GlobalFooter />
          </Router>
        </VStack>
      </ThemeableProvider>
    </>
  );
}

export default App;

import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, HStack, Section, Container, VStack, Space, Divider, Loader, Media, Title1, Title2, Title3, Headline, Body, Subhead, Footnote, Caption1, Caption2, Icon } from '@themeable/react';
import { Cell, Tag } from '@themeable/react-components';
import GlobalFooter from '../../Patterns/GlobalFooter'
import Hero from './Hero';

function Blog({ globals, setGlobals }) {
  const [loading, setLoading] = useState(true);
  const [filteredLayout, setFilteredLayout] = useState(false);
  const [tagNames, setTagNames] = useState([]);
  const [activeTag, setActiveTag] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('tag');
    if (token) {
      console.log(token)
      setActiveTag(decodeURIComponent(token));
      fetch(`/api/filter-blogs/${decodeURIComponent(token)}`)
        .then((res) => res.json())
        .then((res) => {
          setGlobals((prev) => ({
            ...prev,
            blogs: res
          }));
          setFilteredLayout(true)
        })
    } else {
      fetch('/api/all-blogs')
        .then((res) => res.json())
        .then((res) => {
          setGlobals((prev) => ({
            ...prev,
            blogs: res
          }));
        })
        .catch((error) => { console.error('Error fetching data: ', error); });
    }
  }, []);


  useEffect(() => {
    setGlobals((prev) => ({
      ...prev,
      page: 'blog',
      pageColor: '--color-background-flat',
      pageTitle: 'Blog | Certified Mom',
      pageDescription: "Hi! I’m Jessica, a Certified Pediatric Nurse (CPN) and mama to a wild toddler named Isaac. Certified Mom is my place to serve you with THE best mom and baby tips. I cover everything from pregnancy to postpartum and beyond."
    }));
  }, [])



  useEffect(() => {
    if (globals.blogs != null) {
      setLoading(false);
      const tags = globals.blogs.live.flatMap(blog =>
        blog.properties.Tags.multi_select.map(tag => tag.name)
      );
      const uniqueTags = [...new Set(tags)];
      setTagNames(uniqueTags);
    }
  }, [globals.blogs]);


  const filterBlogsBy = useCallback((criteria) => {
    if (criteria !== undefined) {
      fetch(`/api/filter-blogs/${criteria}`)
        .then((res) => res.json())
        .then((res) => {
          setGlobals((prev) => ({
            ...prev,
            blogs: res
          }));
          navigate(`/blog?tag=${encodeURIComponent(criteria)}`)
          setFilteredLayout(true)
          setActiveTag(criteria)
        })
    } else {
      fetch('/api/all-blogs')
        .then((res) => res.json())
        .then((res) => {
          setGlobals((prev) => ({
            ...prev,
            blogs: res
          }));
          navigate(`/blog`)
          setActiveTag('')
          setFilteredLayout(false)
        })
        .catch((error) => { console.error('Error fetching data: ', error); });
    }
  });



  return (
    <>

      {/* Hero */}
      <Hero />
      <Section>

        {/* Topics tag/chip filter bar */}
        <HStack align='top'>
          <HStack group wrap align='left middle'>
            <Subhead width='hug'>
              Topics:
            </Subhead>
            <Space.Content />
            {loading ? (
              <>
                <Button width='hug' variant='secondary' loading small />
                <Button width='hug' variant='secondary' loading small />
                <Button width='hug' variant='secondary' loading small />
              </>
            ) : (
              <>
                {tagNames.map((element, index) => (
                  <React.Fragment key={index}>
                    {activeTag === element ? (
                      <Button
                        key={index}
                        small
                        iconLeading='CloseCircle'
                        title={element}
                        variant='primary'
                        width='hug'
                        onClick={() => filterBlogsBy()}
                      />
                    ) : (
                      <Button
                        key={index}
                        small
                        title={element}
                        width='hug'
                        variant='secondary'
                        onClick={() => filterBlogsBy(element)}
                      />
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </HStack>
        </HStack>
        <Divider />


        {filteredLayout ? (<>
          <HStack gridExpanded={[1, 1, 1, 1, 1, 1]} gridMedium={[1, 1, 1, 1, 1]} gridCompact={[1, 1]} align='top'>
            {loading ?
              <>
                <Loader />
              </> : <>
                {globals.blogs.live.map((blog, index) => (

                  <Button variant='quaternary' onClick={() => navigate(`../blog/${blog.properties.URL.url}`)}>
                    <VStack group>
                      <Media
                        imageSrc={blog.properties.Cover.files[0]?.file.url}
                        caption={blog.properties.Cover.files[0]?.name}
                        aspectRatio='4-3'
                        width='100%'
                      />
                      <Subhead modifier='bold'>
                        {blog.properties.Name.title[0]?.plain_text}
                      </Subhead>
                      <Space.Content />
                      <HStack wrap>
                        {blog.properties.Tags.multi_select.map((tag, index) => (
                          <Tag status='neutral' text1={tag.name} />
                        ))}
                      </HStack>
                    </VStack>
                  </Button>
                ))}
              </>}
          </HStack>

        </>) : (<>

          {/* Featured + 3 newest blogs */}
          {loading ?
            <>
              <Loader />
            </> : <>
              <HStack
                sectionSpacing
                gridExpanded={[1, 1]}
                gridMedium={[1, 1]}
                gridCompact={[1]} >

                {/* Featured */}
                <HStack align='top'>
                  <Media
                    imageSrc={globals.blogs.favorite?.properties.Cover.files[0]?.file.url}
                    alt={globals.blogs.favorite?.properties.Cover.files[0]?.name}
                    size='large'
                    aspectRatio='1 / 1' />
                  <VStack>
                    <VStack align='top left' group>
                      <Subhead color='text-secondary'>Featured</Subhead>
                      <Title3 color='primary'>{globals.blogs.favorite?.properties.Name.title[0].plain_text}</Title3>
                      <Space.Content />
                      <Subhead color='text-secondary'>{globals.blogs.favorite?.properties.Description?.rich_text[0].plain_text}</Subhead>
                      <Space.Content />
                    </VStack>
                    <Button
                      small
                      title='Read now'
                      variant='tertiary'
                      width='hug'
                      iconTrailing='ChevronRight'
                      onClick={() => navigate(`../blog/${globals.blogs.favorite?.properties.URL.url}`)} />
                  </VStack>
                </HStack>


                <VStack showOn='compact'>
                  <Space.NewSection />
                  <Divider />
                  <Space.NewSection />
                </VStack>



                {/* Top 3 newest */}
                <VStack>
                  {globals.blogs.live.slice(0, 3).map((blog, index) => (
                    <Cell variant='quaternary' onClick={() => navigate(`../blog/${blog.properties.URL.url}`)} key={index}>
                      <HStack align='top' key={index}>
                        <VStack key={index}>
                          <HStack key={index}>
                            {blog.properties.Tags.multi_select.map((tag, index) => (
                              <Tag status='neutral' text1={tag.name} key={index} />
                            ))}
                          </HStack>
                          <Headline>{blog.properties.Name.title[0]?.plain_text}</Headline>
                          <Footnote color='text-secondary'>
                            {blog.properties.Description?.rich_text[0].plain_text}
                          </Footnote>
                        </VStack>
                        <Media
                          imageSrc={blog.properties.Cover.files[0]?.file.url}
                          size='medium'
                          aspectRatio='4-3'
                        />
                      </HStack>
                      <Space.Content />
                    </Cell>
                  ))}
                </VStack>
              </HStack>

            </>}
          <Space.NewSection />


          <HStack gridExpanded={[1, 1, 1, 1, 1, 1]} gridMedium={[1, 1, 1, 1, 1]} gridCompact={[1, 1]} align='top'>
            {loading ?
              <>
                <Loader />
              </> : <>
                {globals.blogs.live.slice(3).map((blog, index) => (

                  <Button variant='quaternary' onClick={() => navigate(`../blog/${blog.properties.URL.url}`)}>
                    <VStack group align='left'>
                      <Media
                        imageSrc={blog.properties.Cover.files[0]?.file.url}
                        caption={blog.properties.Cover.files[0]?.name}
                        aspectRatio='4-3'
                        wrapperStyle={{ width: '100%' }}
                      />
                      <Subhead modifier='bold'>
                        {blog.properties.Name.title[0]?.plain_text}
                      </Subhead>
                      <Space.Content />
                      <HStack wrap>
                        {blog.properties.Tags.multi_select.map((tag, index) => (
                          <Tag status='neutral' text1={tag.name} />
                        ))}
                      </HStack>
                    </VStack>
                  </Button>
                ))}
              </>}
          </HStack>

        </>
        )}
      </Section>


    </>
  )
}

export default Blog

import React from 'react';
import { Media, Section, HStack, VStack, Title2, Body, Button, Space } from '@themeable/react'
import { useNavigate } from 'react-router-dom';

function MeetTheFam() {
  const navigate = useNavigate();
  return (
    <>
      <Section>
        <HStack gridExpanded={[1, 1]} gridMedium={[1, 1]} gridCompact={[1]} sectionSpacing align='middle'>
          <Media
            caption='image of my family'
            imageSrc='../assets/redeeming-special-needs-family-photo.png'
            aspectRatio='1 / 1'
          />
          <VStack>
            <Space.NewSection />
            <Space.NewSection />
            <VStack group style={{ maxWidth: '400px' }}>
              <Title2>
                This is Us
              </Title2>
              <Body>
                We love Jesus and eating gluten free mac n cheese for dinner.
              </Body>
              <Space.Content />
              <Space.Content />
              <Button variant='primary' title='Get to Know Us' width='hug' iconTrailing='ArrowRight' onClick={() => navigate('../about')} />
            </VStack>
            <Space.NewSection />
            <Space.NewSection />
          </VStack>
        </HStack>
      </Section>
    </>
  )
}

export default MeetTheFam

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Tag, Cell } from '@themeable/react-components';
import { Button, HStack, Section, Content, Container, VStack, Space, Divider, Loader, Media, Title1, Title2, Title3, Headline, Body, Subhead, Footnote, Caption1, Caption2, Icon, Input } from '@themeable/react'
import Blockquote from '../../Patterns/Blockquote';
import { AuthorInfo } from './AuthorInfo';
import { DisclaimerAffiliate, DisclaimerMedical } from './Disclaimers';
import { BlogSuggestion } from './Suggestion';


function BlogEntry({ globals, setGlobals }) {
  const [loading, setLoading] = useState(true);
  const { param } = useParams();
  const [pageTitle, setPageTitle] = useState('');
  const [blocks, setBlocks] = useState('');
  const [tags, setTags] = useState('');
  const [medical, setMedical] = useState(false);
  const [affiliate, setAffiliate] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setGlobals((prev) => ({
      ...prev,
      page: '',
      pageColor: '--color-background-elevated'
    }));
  }, [])

  useEffect(() => {
    fetch(`/api/blog/${param}`)
      .then((res) => res.json())
      .then((res) => {
        setPageTitle(res.Name)
        setBlocks(res.Blocks)
        setLoading(false)
        setTags(res.Tags)
        res.Disclaimers.map((disclaimer) => {
          if (disclaimer?.name === 'medical') {
            setMedical(true)
          } else if (disclaimer?.name === 'affiliate') {
            setAffiliate(true)
          }
        })
      })
      .then((res) => {
        setGlobals((prev) => ({
          ...prev,
          blogs: res
        }));
      })
      .catch((error) => { console.error('Error fetching data: ', error); });
  },
    []);




  return (
    <>
      <Section>
        <HStack
          sectionSpacing
          gridExpanded={[2, 3]}
          gridMedium={[1, 1]}
          gridCompact={[1]}
        >
          <HStack>
            <VStack style={{ position: 'relative' }} align='top'>
              <HStack group>
                {tags && (tags.map((tag, index) => (
                  <Tag status='neutral' key={index} text1={tag} />
                )))}
              </HStack>
              <Title1>
                {pageTitle}
              </Title1>

              {medical && (
                <DisclaimerMedical />
              )}
              {affiliate && (
                <DisclaimerAffiliate />
              )}
              <Divider />

              <AuthorInfo />

              <Divider />

              <Container variant='fill'>
                <Subhead color='text-secondary'>Jump to topic:</Subhead>
                {loading ? <>
                  <Button loading small variant='secondary' />
                  <Button loading small variant='secondary' />
                </> : <>
                  <VStack align='top'>
                    {blocks.map((block, index) => (
                      <>
                        {(() => {
                          switch (block.type) {
                            case 'heading_1': return (
                              <>
                                {block.heading_1.rich_text[0].plain_text.includes('##') ? (
                                  <>
                                    <Divider nested />
                                    <Cell onClick={() => navigate(`#${block.heading_1.rich_text[0].plain_text.substring(4).toLowerCase().trim().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}`)} >
                                      <HStack align='middle center'>
                                        <Icon
                                          name={block.heading_1.rich_text[0].plain_text.substring(2, 4).trim()}
                                          color='accent'
                                        />
                                        <Space.Content />
                                        <Body weight='bold'>{block.heading_1.rich_text[0].plain_text.substring(3)}</Body>
                                      </HStack>
                                    </Cell>
                                  </>
                                ) : (
                                  <>
                                    <Divider nested />
                                    <Cell onClick={() => navigate(`#${block.heading_1.rich_text[0].plain_text.toLowerCase().trim().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}`)} >
                                      <Body weight='bold'>{block.heading_1.rich_text[0].plain_text}</Body>
                                    </Cell>
                                  </>
                                )}
                              </>
                            )
                              break;
                            case 'heading_2': return (
                              <>
                                {block.heading_2.rich_text[0].plain_text.includes('##') ? (
                                  <Cell variant='link' url={`#${block.heading_2.rich_text[0].plain_text.substring(4).toLowerCase().trim().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}`} >
                                    <HStack>
                                      <Icon
                                        name={block.heading_2.rich_text[0].plain_text.substring(2, 4).trim()}
                                        color='accent'
                                      />
                                      <Footnote>{block.heading_2.rich_text[0].plain_text.substring(3)}</Footnote>
                                    </HStack>
                                  </Cell>
                                ) : (
                                  <Cell variant='link' url={`#${block.heading_2.rich_text[0].plain_text.substring(4).toLowerCase().trim().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}`} >
                                    <HStack>
                                      <Space.Content />
                                      <Space.Content />
                                      <Footnote>{block.heading_2.rich_text[0].plain_text.substring(3)}</Footnote>
                                    </HStack>
                                  </Cell>
                                )}
                              </>
                            )
                              break;
                            default: return ''
                              break;
                          }
                        })()}
                      </>
                    ))}

                  </VStack>
                </>}

              </Container>
              <Space.NewSection />

              {/* Illustration */}
              {loading ? <></> : <>
                <Space.Grow />
              </>
              }
            </VStack>
            {/* <HStack showOn='medium expanded'> */}
            {/*   <Divider vertical /> */}
            {/* </HStack> */}
          </HStack>

          {/* Right column */}
          <VStack>
            <HStack showOn='compact'>
              <Divider />
              <Space.NewSection />
            </HStack>
            {loading ? <></> : <>
              {blocks.map((block, index) => (
                <div key={index}>
                  {(() => {
                    switch (block.type) {
                      case 'paragraph': return (
                        <Body tag='p'>
                          {block.paragraph.rich_text.map((chunk, index) => (
                            <>
                              {chunk.href ? (
                                <a
                                  style={{ color: 'rgba(var(--color-primary))' }}
                                  href={chunk.href}
                                  target={chunk.href.includes('localhost') || chunk.href.includes('redeemingspecialneeds.com') ? undefined : '_blank'}
                                  rel={chunk.href.includes('localhost') || chunk.href.includes('redeemingspecialneeds.com') ? undefined : 'noopener noreferrer'}
                                >
                                  {chunk.plain_text}
                                </a>
                              ) : chunk.annotations?.bold ? (
                                <span>
                                  <strong>
                                    {chunk.plain_text}
                                  </strong>
                                </span>
                              ) : chunk.annotations?.italic ? (
                                <span>
                                  <em>
                                    {chunk.plain_text}
                                  </em>
                                </span>
                              ) : (
                                <>
                                  <span>
                                    {chunk.plain_text}
                                  </span>
                                </>
                              )}
                            </>
                          ))}
                        </Body>
                      )
                        break;
                      case 'heading_1': return (
                        <>
                          <Space.NewSection />
                          {block.heading_1.rich_text[0].plain_text.includes('##') ? (
                            <span id={block.heading_1.rich_text[0].plain_text.substring(3).trim().toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}>
                              <HStack align='middle'>
                                <Icon
                                  name={block.heading_1.rich_text[0].plain_text.substring(2, 4).trim()}
                                  color='accent'
                                  size='80px'
                                />
                                <Title2 tag='h2' color='accent'>
                                  {block.heading_1.rich_text[0].plain_text.substring(3)}
                                </Title2>
                              </HStack>
                            </span>
                          ) : (
                            <span id={block.heading_1.rich_text[0].plain_text.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}>
                              <Title2 tag='h2' color='accent'>
                                {block.heading_1.rich_text[0].plain_text}
                              </Title2>
                            </span>
                          )}
                        </>
                      )
                        break;
                      case 'heading_2': return (
                        <>
                          <Space.NewSection />
                          {block.heading_2.rich_text[0].plain_text.includes('##') ? (
                            <span id={block.heading_2.rich_text[0].plain_text.substring(3).trim().toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}>
                              <HStack align='middle'>
                                <Icon
                                  name={block.heading_2.rich_text[0].plain_text.substring(2, 4).trim()}
                                  color='accent'
                                  size='64px'
                                />
                                <Title3 tag='h3' color='accent'>
                                  {block.heading_2.rich_text[0].plain_text.substring(3)}
                                </Title3>
                              </HStack>
                            </span>
                          ) : (
                            <span id={block.heading_2.rich_text[0].plain_text.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}>
                              <Title3 tag='h3' color='accent'>
                                {block.heading_2.rich_text[0].plain_text}
                              </Title3>
                            </span>
                          )}
                        </>
                      )
                        break;
                      case 'heading_3': return (
                        <span id={block.heading_3.rich_text[0].plain_text.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}>
                          <Headline tag='h4'>
                            {block.heading_3.rich_text[0].plain_text}
                          </Headline>
                        </span>
                      )
                        break;
                      case 'quote': return (
                        <Blockquote>
                          {block.quote.rich_text[0].plain_text}
                        </Blockquote>
                      )
                        break;
                      case 'divider': return (
                        <Divider />
                      )
                        break;
                      case 'callout': return (
                        <Container variant='fill'>
                          <Section>
                            <HStack>
                              <Body>
                                {block.callout.icon.emoji}
                              </Body>
                              <Body tag='h5'>
                                {block.callout.rich_text[0].plain_text}
                              </Body>
                            </HStack>
                          </Section>
                        </Container>
                      )
                        break;
                      case 'image': return (
                        <>
                          <Media
                            caption={block?.image?.caption[0]?.plain_text || null}
                            imageSrc={block.image.file.url}
                            showCaption
                          />
                        </>
                      )
                        break;
                      case 'numbered_list_item': return (
                        <>
                          <Body>
                            <span>- {block.numbered_list_item.rich_text[0].plain_text}
                            </span>
                          </Body>
                        </>
                      )
                        break;
                      case 'bulleted_list_item': return (
                        <>
                          <Body>
                            <span>- {block.bulleted_list_item.rich_text[0].plain_text}
                            </span>
                          </Body>
                        </>
                      )
                        break;
                      default: return ''
                        break;
                    }
                  })()}
                </div>
              ))}
            </>}
          </VStack>
        </HStack>
      </Section>

      <Divider extendLeft extendRight />

      <BlogSuggestion />

    </>
  )
}

export default BlogEntry

import { Body, Button, Container, Input, Section, Space } from "@themeable/react"
import { HStack, Title1, VStack } from "@themeable/react-components"
import { useState } from "react";


export const BlogSuggestion = () => {

  const [suggestion, setSuggestion] = useState('');
  const [suggestionSent, setSuggestionSent] = useState(false);

  const sendSuggestion = () => {

    const currentPageUrl = window.location.href; // Get current page URL
    const message = suggestion
    fetch('/api/send-suggestion', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message, currentPageUrl }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setSuggestionSent(true);
        console.log('Form submitted successfully:', data);
      })
      .catch((error) => {
        console.error('Error sending form data:', error);
      });
  };


  return (
    <Section>
      <HStack align="center middle">
        <VStack maxWidth={'600px'}>
          {!suggestionSent ? (<>
            <VStack group>
              <Title1 color='primary' >Suggestions?</Title1>
              <Space.Content />
              <Body>
                Send me ideas for future topics or if you have any feedback/questions I would love to hear from you, mama!
              </Body>
            </VStack>
            <Container interactive variant='fill'>
              <Input
                multiLine
                maxNumberOfLines={4}
                numberOfLines={3}
                onChange={(e) => { setSuggestion(e.target.value) }}
                placeholder='What did you think❓💜' />
            </Container>
            {suggestion && (
              <Button
                onClick={() => { sendSuggestion() }}
                title='Send'
                variant='primary' />
            )}
          </>
          ) : (
            <>
              <Title1 color='primary'>Love it! 🎉</Title1>
              <Body>
                Thanks for the suggestion! I take these suggestions very seriously. Thank you for your advice!
              </Body>
            </>
          )}

        </VStack>
      </HStack>
    </Section>
  )
}

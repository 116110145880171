import React from 'react'
import { Section, HStack, Title3, VStack, Body, Space, Icon, Subhead, Headline, Caption1, Title2, Container, Caption2, Footnote, Media, Divider } from '@themeable/react'

function PersonalLife() {

  return (
    <>
      <Section>
        <HStack align='center'>
          <Title2 color='accent' align='center' style={{ maxWidth: '700px' }}>
            Now that you have some insight into my vision for Certified Mom, here’s a taste of my personal life:
          </Title2>
        </HStack>
        <HStack sectionSpacing gridExpanded={[1, 1]} gridCompact={[1]} gridMedium={[1, 1]} align='middle'>
          <Media
            imageSrc='https://res.cloudinary.com/create-new-entry/image/upload/v1593892120/certifiedmom/partials/certified-mom-binge-worthy-shows-nurse.jpg'
          />
          <VStack group>
            <Body>
              When I’m not working, you can catch me in comfy clothes chasing my boys and taking full advantage of nap time.
            </Body>
            <Space.Content />
            <Divider />
            <Space.Content />
            <HStack align='middle'>
              <Headline color='primary' align='left' width='hug'>
                Nap Time
              </Headline>
              <Space.Content />
              <Headline width='hug' color='primary'>=</Headline>
              <Space.Content />
              <Headline color='primary'>
                Time to watch every medical drama I can get my hands on...
              </Headline>
            </HStack>
            <Space.Content />
            <Divider />
            <Space.Content />
            <Body>
              I soak up every minute that I can with my sons and hunk of a husband.
            </Body>
          </VStack>
        </HStack>

        <Space.NewSection />

        <HStack
          sectionSpacing
          gridExpanded={[1, 1]}
          gridCompact={[1]}
          gridMedium={[1, 1]}
          align='middle'
        >
          <Body>
            But sometimes we all need a little alone time! When I do get a minute to myself, I’m a huge fan of KSL workouts, reading Christian romances, and going for walks with my rescue dogs. I’m also super devoted to enjoying Hu chocolate or Siete churro chips every night after Isaac and Micah go to sleep.
          </Body>
          <Media
            imageSrc='./assets/about/dessert.jpg'
            aspectRatio='4 / 3'
          />
        </HStack>
      </Section>

      <Container
        style={{
          zIndex: '1',
          backgroundImage: 'url(https://res.cloudinary.com/create-new-entry/image/upload/v1593894367/certifiedmom/partials/certified-mom-beach-nurse-about.gif)',
          // backgroundImage: './assets/about/dessert.jpg',
          backgroundBlendMode: 'overlay',
          backgroundColor: 'rgba(var(--color-background-flat), 0.7)',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }}
        align='center middle'>
        <Section>
          <Space.NewSection />
          <VStack>
            <Title2 align='center'>
              Other than Home,<br />
              the Beach is my Happy Place
            </Title2>
            <Body align='center'>
              While I love being home with my family, I can’t resist being outside when it’s sunny and beautiful, which is often here in Southern Orange County, CA! The beach is my favorite outdoor spot. All of my worries melt away when I dip my toes in the water and feel the sand squish beneath my feet. Yet, the best feeling is swimming in the cool water and diving underneath the waves. There’s something so freeing about floating in an endlessly huge body of water.
            </Body>
          </VStack>
          <Space.NewSection />
        </Section>
      </Container>

    </>
  )
}

export default PersonalLife

import React, { useEffect } from 'react';
import { Container, Title1 } from '@themeable/react-components'
import Hero from './Hero'
import Overview from './Overview'
import Faith from './Faith'
import Family from './Family'
import Outro from './Outro'

import { Divider, Section, HStack, VStack, Title3, Body, Space } from '@themeable/react-components'
import Certifications from './Certifications';
import PersonalLife from './PersonalLife';
import FunFacts from './FunFacts';

function About({ setGlobals }) {

  useEffect(() => {
    setGlobals((prev) => ({
      ...prev,
      page: 'about',
      pageColor: '--color-background-flat',
      pageTitle: 'About | Certified Mom',
      pageDescription: "Hi! I’m Jessica, a Certified Pediatric Nurse (CPN), Birth and Postpartum Doula, and mama to two boys Isaac and Micah. Certified Mom is my place to serve you with THE best mom and baby tips. I cover everything from pregnancy to postpartum and beyond."
    }));
  }, [])


  return (<>

    <Hero />
    <Certifications />
    <Container variant='fill'>
      <Overview />
    </Container>
    <PersonalLife />
    <Space.NewSection />
    <Space.NewSection />
    <FunFacts />
    <Family />

    <Section>
      <Divider />
    </Section>

    <Outro setGlobals={setGlobals} />

    <Space.NewSection />

  </>)
}

export default About

import React from 'react'
import { Media, Section, HStack, VStack, Title2, Body, Space, Title3, Caption1, Container } from '@themeable/react'
import { getAge } from '../../utils/ageCalculator'

function Overview() {

  return (<>
    <Section>
      <HStack gridExpanded={[1, 1]} gridCompact={[1]} gridMedium={[1, 1]} align='middle'>
        <HStack>
          <VStack>
            <Title3 color='primary'>Why "Certified"</Title3>
            <Body>
              I’ve been a pediatric nurse for {getAge('04/01/2016')} years and I absolutely LOVE my job. As much as I enjoy it, I am limited to the medical care of my patients. I wanted to create this space to share my knowledge and experience with as many mamas as possible.
            </Body>
            <Body>
              As a new mom I felt overwhelmed by all of the different approaches to parenting. I am here to reassure you that you are doing a fantastic job mama! You are more than enough for your baby, partner, and Savior. I hope you leave this space with some extra tools in your tool belt, and also feel affirmed in who YOU are as a mom and daughter of the king.
            </Body>
            <Space.NewSection showOn='compact' />
          </VStack>
          <Space.NewSection />
        </HStack>
        <Media
          imageSrc='https://res.cloudinary.com/create-new-entry/image/upload/v1593889388/certifiedmom/partials/about-certified-mom-perdiatric-nurse.jpg'
          aspectRatio='1 / 1'
        />
      </HStack>
    </Section>
  </>)
}

export default Overview

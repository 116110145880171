export function getAge(birthday) {
  // Parse the birthday string (MM/DD/YY)
  const [month, day, year] = birthday.split('/').map(Number);

  // Convert 2-digit year to 4-digit (assuming 21st century for simplicity)
  const fullYear = year < 100 ? 2000 + year : year;

  // Create Date objects
  const birthDate = new Date(fullYear, month - 1, day); // Month is 0-indexed in JS
  const today = new Date('2025-04-07'); // Hardcoded to current date (April 7, 2025)

  // Calculate the difference in years
  let age = today.getFullYear() - birthDate.getFullYear();

  // Adjust if birthday hasn't occurred this year yet
  const hasHadBirthdayThisYear =
    today.getMonth() > birthDate.getMonth() ||
    (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate());

  if (!hasHadBirthdayThisYear) {
    age--;
  }

  return age;
}

// Example usage
// const sonBirthday = '04/02/22'; // April 2, 2022
// const age = calculateAge(sonBirthday);
// console.log(`Your son is ${age} years old`); // Output: "Your son is 3 years old"

import { Body, HStack, Icon, Section, Space, Title1, VStack } from '@themeable/react'
import React from 'react'

function Hero() {
  return (
    <VStack style={{ overflow: 'hidden' }}>

      <HStack style={{ position: 'relative' }} showOn='medium expanded'>
        <div style={{ position: 'absolute', transform: 'translate(-50px, -50px) rotate(15deg)', opacity: '.8' }}>
          <Icon
            name='Baby'
            size='350px'
            color='primary'
          />
        </div>
      </HStack>


      <VStack style={{
        background: `
				linear-gradient(to bottom, 
				rgba(255, 255, 255, 0.2), 
				rgba(0, 0, 0, 0.06))`}}>
        <Section style={{ maxWidth: '800px' }}>

          <HStack gridExpanded={[1, 1]} gridCompact={[1]} gridMedium={[1, 1]} align='middle'>
            <HStack style={{ minWidth: '350px' }} align='center'>
              <Space.NewSection showOn='medium expanded' />
              <HStack style={{ position: 'relative' }}>

                <div style={{
                  position: 'absolute',
                  transform: 'rotate(45deg)',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                  opacity: '.1'
                }}>
                  <Icon
                    name='PatternFlower'
                    size='100%'
                    color='primary'
                  />
                </div>

                <Title1 width='hug' align='left'>The Blog</Title1>
              </HStack>
            </HStack>
            <Body color='--color-text-secondary'>
              Hi! I’m Jessica, a Certified Pediatric Nurse (CPN) and mama to 2 precious boys, Isaac and Micah. Certified Mom is my place to serve you with THE best mom and baby tips. I cover everything from pregnancy to postpartum and beyond.
            </Body>
          </HStack>
        </Section>
      </VStack>

      <HStack style={{ position: 'relative' }} showOn='medium expanded'>
        <div style={{ position: 'absolute', right: '56px', bottom: '56px', transform: 'translate(30%, 30%) rotate(-25deg)', }}>
          <Icon
            name='Breastfeeding'
            size='300px'
            color='accent'
          />
        </div>
      </HStack>

    </VStack>
  )
}

export default Hero

import { Cell, Divider, Sheet } from '@themeable/react-components'

import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import './Navbar.css'
import { Logo, LogoShort } from './Logo';
import { collection, addDoc, Timestamp, arrayUnion, updateDoc, doc, addField } from "firebase/firestore";
import { db, auth } from '../services/firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { Icon, Button, Tab, VStack, Input, Container, HStack, Title3, Body, Section, Space, Subhead, Headline, Label } from '@themeable/react';


export const navItems = [
  {
    name: 'Home',
    url: '../'
  },
  {
    name: "Blog",
    url: '../blog'
  },
  {
    name: 'About',
    url: '../about'
  },
  {
    name: 'Services',
    url: '../doula'
  }
]

function Navbar({ setGlobals, globals }) {
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  const navbarRef = useRef('--color-positive');
  // const [isFormSheetActive, setIsFormSheetActive] = useState(false);
  const toggleFormSheet = () => {
    setGlobals((prev) => ({
      ...prev,
      sayHelloSheet: !globals.sayHelloSheet
    }));
  };


  const loginWithGoogle = () => {
    const user = auth.currentUser;

    if (user) {
      // User is already signed in
      console.log("User is already signed in:", user);
      // You may redirect or show a message indicating the user is already signed in
      return;
    }

    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        // Handle successful sign-in
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        // ...
      })
      .catch((error) => {
        // Handle sign-in errors
        console.error("Error signing in:", error);
      });
  };


  const handleLogout = () => {
    auth.signOut()
      .then(() => {
        // Sign-out successful.
        console.log("User signed out successfully");
      })
      .catch((error) => {
        // An error happened.
        console.error("Error signing out:", error);
      });

  };

  // useEffect(() => {
  // 	const user = auth.currentUser
  // 	// console.log(user)
  // }, []) 

  const [formSuccess, setFormSuccess] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')


  const sendHello = () => {
    const isValidName = (name) => name && /^[a-zA-Z-' ]+$/.test(name);
    const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    if (!isValidName(name)) {
      console.error('Invalid name');
      setErrorMessage('Invalid name');
      return;
    }

    if (!isValidEmail(email)) {
      console.error('Invalid email');
      setErrorMessage('Invalid email');
      return;
    }

    const currentPageUrl = window.location.href; // Get current page URL

    fetch('/api/contact-us', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, message, currentPageUrl }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setFormSuccess(true);
        console.log('Form submitted successfully:', data);
      })
      .catch((error) => {
        console.error('Error sending form data:', error);
        setErrorMessage('Error sending form data');
      });
  };


  const hamburgerRef = useRef();
  const [hamburgerActive, setHamburgerActive] = useState(false);






  return (
    <>
      <nav
        className={`navbar`}
        style={{ background: `rgba(var(${globals.pageColor}), .95)` }}>
        <VStack>
          <Section style={{ paddingTop: '0px', paddingBottom: '0px' }}>
            <HStack>
              <Space.Grow showOn='compact' />
              <LogoShort width='44px' />


              {/* <Button type='button' text1='Log In' onClick={() => {loginWithGoogle()}}/> */}
              {/* <Button type='button' text1='Log Out' onClick={() => {handleLogout()}}/> */}


              {/* Desktop navbar */}
              <HStack className='navTabs' showOn='medium expanded' align='right'>
                {navItems.map((obj) => (
                  <Tab
                    title={obj.name}
                    small
                    onClick={() => navigate(obj.url)}
                    indicator='bottom'
                    active={globals.page === obj.name.toLowerCase()} />
                ))}
                <Divider vertical />
                <Button variant='tertiary' width='hug' small title='Say Hello' onClick={() => { toggleFormSheet() }} />
              </HStack>


              <HStack showOn='compact' align='right middle'>
                <Button
                  ref={hamburgerRef}
                  className='navHamburger'
                  iconLeading='Hamburger'
                  variant='quaternary'
                  small
                  width='hug'
                  onClick={() => setHamburgerActive(true)}
                />
                <Sheet
                  active={hamburgerActive}
                  anchor={hamburgerRef}
                  onDismiss={() => setHamburgerActive(false)}
                >
                  <Section>
                    {navItems.map((obj) => (
                      <Cell onClick={() => { navigate(obj.url); setHamburgerActive(false); }}>
                        <Body
                          weight={globals.page === obj.name.toLowerCase() ? 'bold' : undefined}
                          color={globals.page === obj.name.toLowerCase() ? 'primary' : undefined}
                        >
                          {obj.name}
                        </Body>
                      </Cell>
                    ))
                    }
                    <Divider />
                    {/* <Button 
											text1='Doula' url='../doula' type='link'
											variant='primary' icon2='ArrowRight'/> */}
                    <Cell onClick={() => { toggleFormSheet(); setHamburgerActive(false) }}>
                      <HStack align='middle'>
                        <Body color='primary' >
                          Say Hello
                        </Body>
                        <Icon name='Heart' color='primary' />
                      </HStack>
                    </Cell>
                  </Section>
                </Sheet>
              </HStack>

            </HStack>
            <Divider nested />
          </Section>
        </VStack>
      </nav>

      <Sheet
        size="medium"
        active={globals.sayHelloSheet}
        color='--color-background-flat'
        onDismiss={() => { toggleFormSheet() }}
      >
        <Section
          height='fill'
          style={{
            backgroundColor: 'rgb(var(--color-background-flat))'
          }}
        >
          <HStack gridCompact={[1, 1, 1]} gridMedium={[1, 1, 1]} gridExpanded={[1, 1, 1]}>
            <div />
            <VStack align='center middle'>
              <Headline align='center'>
                Say Hello
              </Headline>
            </VStack>
            <VStack align='right'>
              <Button small variant='secondary' width='hug' iconLeading='Close' onClick={() => { toggleFormSheet() }} />
            </VStack>
          </HStack>
          {formSuccess ? (<>
            <Title3>
              Thanks!
            </Title3>
            <Body>
              I'm looking forward to reading your message.
            </Body>
          </>) : (<>
            <Title3>Hi!</Title3>
            <Body>
              I truly can’t wait to hear from you! Hit me with your questions, comments, or suggestions here.
            </Body>
            <Space.Content />
            <Space.Content />
            <form className='v-stack width-fill'>
              <HStack>
                <Label label='Name'>
                  <Container>
                    <Input onChange={((e) => { setName(e.target.value) })} />
                  </Container>
                </Label>
                <Label label='Email'>
                  <Container>
                    <Input onChange={((e) => { setEmail(e.target.value) })} />
                  </Container>
                </Label>
              </HStack>

              <Label label='Message'>
                <Container>
                  <Input multiLine numberOfLines={2} maxNumberOfLines={5} onChange={((e) => { setMessage(e.target.value) })} />
                </Container>
              </Label>
              {errorMessage &&
                <Subhead color='--color-negative'>{errorMessage}</Subhead>
              }
              <Space.Content />
              <HStack align='middle'>
                <Button
                  onClick={() => { toggleFormSheet() }}
                  title='Cancel'
                />
                <Button
                  type='submit'
                  onClick={((e) => { sendHello() })}
                  variant='primary'
                  title='Send'
                />
              </HStack>
            </form>
            {/* <Divider /> */}
            {/* <Button text1='Book A Call' type='link' url='https://calendly.com/certifiedmom/doula-meet-and-greet' /> */}
          </>)}
        </Section>
      </Sheet>
    </>
  )
}

export default Navbar

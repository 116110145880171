import { Button, Footnote, Headline, HStack, Media, Subhead, VStack } from "@themeable/react"


export const AuthorInfo = () => {


  return (
    <HStack align='middle'>
      <Media
        profile
        imageSrc='../assets/redeeming-special-needs-profile-jessica-kounter.jpg'
        caption='Image of Jessica'
        size='medium'
      />
      <VStack align='top left'>
        <VStack group>
          <Headline>Jessica</Headline>
          <Subhead>RN, BSN, CPN  |  Pediatric Nurse & Doula</Subhead>
          <Footnote>Franklin, TN</Footnote>
        </VStack>
        <Button
          variant='tertiary'
          small
          title='Learn more'
          width='hug'
          onClick={() => navigate('../about')} />
      </VStack>
    </HStack>
  )
}

import React from 'react'
import { Section, HStack, Title3, VStack, Body, Space, Icon, Subhead, Headline, Caption1, Title2, Container, Caption2, Footnote, Media } from '@themeable/react'

function FunFacts() {

  const certifications = [
    {
      src: 'https://res.cloudinary.com/create-new-entry/image/upload/c_fill,w_400,h_400/v1593894834/certifiedmom/partials/about-certified-mom-cheese.jpg',
      title: 'Cheese',
      subtitle: 'There is no limit to my love for cheese-cheesy pasta, cheesy pizza, cheesy quesadillas, cheesy tv, cheesy books, you name it. If cheese is there, SO AM I...'
    },
    {
      src: 'https://res.cloudinary.com/create-new-entry/image/upload/c_fill,w_400,h_400/v1593894835/certifiedmom/partials/about-certified-mom-mama-shirt-design.jpg',
      title: 'The Summer is My Jam',
      subtitle: 'If I could see the sun 365 days a year I would be stoked.'
    },
    {
      src: 'https://res.cloudinary.com/create-new-entry/image/upload/c_fill,w_400,h_400/v1593894834/certifiedmom/partials/about-certified-mom-dancing.jpg',
      title: 'Dance Breaks Required',
      subtitle: `When I can't solve a problem, I take a dance break with my boys. Nothing better than grooving with my little men to Demi Lovato’s “Sorry Not Sorry”`
    },
    {
      src: 'https://res.cloudinary.com/create-new-entry/image/upload/c_fill,w_400,h_400/v1593894835/certifiedmom/partials/about-certified-mom-uggs.jpg',
      title: 'Uggs',
      subtitle: 'I live in my slippers- I won’t even get up from the couch without my slippers on. I was cleaning barefoot and felt the carpet and I was like oh..so this is what it feels like.'
    },
  ]

  return (
    <>
      <Section style={{ paddingTop: '0px' }} >

        <Title2 color='primary' align='center' >
          Four Fun Facts
        </Title2>
        <Space.Content />
        <HStack gridExpanded={[1, 1, 1, 1]} gridMedium={[1, 1, 1, 1]} gridCompact={[1, 1]}>
          {certifications.map((category, index) => (
            <VStack group layout='left top'>
              <span style={{ position: 'relative' }} className='invert'>
                <VStack
                  className=''
                  style={{
                    position: 'absolute',
                    top: 'var(--space-h)',
                    left: 'var(--space-v)',
                    transform: 'translate(-50%, -50%)',
                    width: '40px',
                    height: '40px',
                    background: 'rgb(var(--color-background-elevated))',
                    borderRadius: '1000px',
                    zIndex: '1',
                    border: '1px solid rgba(var(--color-border), var(--color-border-alpha))'
                  }}
                  align='middle center'>
                  <Headline align='center' color='accent'>{index + 1}</Headline>
                </VStack>
                <Media
                  imageSrc={category.src}
                  aspectRatio='1 / 1'
                />
              </span>
              <Space.Content />
              <Headline color='accent'>{category.title}</Headline>
              <Footnote >{category.subtitle}</Footnote >
            </VStack>
          ))}
        </HStack>

      </Section>
    </>
  )
}

export default FunFacts

import React, { useState, useEffect } from 'react'
import { Section, HStack, VStack, Body, Title3, Headline, Button, Divider, Space, Footnote, Cell } from '@themeable/react-components'
import { Logo } from './Logo'
import { useNavigate } from 'react-router-dom';
import { navItems } from './Navbar';
import { Subhead } from '@themeable/react';

function GlobalFooter() {
  const navigate = useNavigate();

  const [year, setYear] = useState('');

  useEffect(() => {
    setYear(new Date().getFullYear())
  }, [])



  return (<>
    <VStack style={{ background: 'rgb(var(--color-background-flat))' }}>
      <Section>
        <HStack gridExpanded={[1, 1]} gridMedium={[1, 1]} gridCompact={[1, 1]}>
          <Logo width={'200px'} />
          <VStack layout='left'>
            <Headline>
              Navigate to:
            </Headline>

            {navItems.map((item) => (
              <Cell onClick={() => navigate(item.url)}>
                <Subhead>{item.name}</Subhead>
              </Cell>
            ))}

          </VStack>
        </HStack>
        <Space.Content />
        <Divider />
        <HStack>
          <Footnote>
            Copyright © {year} Certified Mom™ LLC. All rights reserved.
          </Footnote>
        </HStack>
      </Section>
    </VStack>
  </>)
}

export default GlobalFooter

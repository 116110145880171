import React from 'react'
import { Section, Title2, Body, HStack, Button } from '@themeable/react'
import { setIsFormSheetActive } from '../../Patterns/Navbar'

function Outro({ setGlobals }) {


  const sayHello = () => {
    setGlobals((prev) => ({
      ...prev,
      sayHelloSheet: true
    }));
  }

  return (
    <Section style={{ maxWidth: '800px' }}>
      <Title2 align='left'>
        I am so looking forward to serving you in your pregnancy and birth!
      </Title2>

      <HStack>
        {/* <Button variant='primary' title='Book a Free Call' url='https://calendly.com/certifiedmom/doula-meet-and-greet?month=2024-05' /> */}
        <Button title='Message Me' variant='accent' onClick={() => { sayHello() }} />
      </HStack>
    </Section>
  )
}

export default Outro

import React from 'react'
import { useThemeable, Divider, Section, Title3, Space, HStack, VStack, Title1, Media, Body, Container, Title2 } from '@themeable/react'
import { getAge } from '../../utils/ageCalculator'

function Family() {

  const { viewportSize } = useThemeable()

  const content = [
    {
      name: 'Brad',
      blocks: [
        `My beloved husband. We’ve been married for ${getAge('07/31/2016')} years! He is the talented website designer/developer behind the screen. This ministry would not be possible without him and the gifts God has blessed him with!`,
        'I met Brad in college at Point Loma Nazarene University in San Diego our junior year. We were both invited to a Christmas event in downtown San Diego and met in our friend’s car on the way there. I invited him and his bff Esteban over for brownies and the rest is history! We had a love at first sight kind of romance. He pursued me sooo well. I felt wanted and treasured by Brad since the beginning. Talk about redemption from previous relationships!',
        'Our marriage has really struggled through the course of having 2 special needs kiddos. You’ll hear lots about our marriage (with Brad’s permission!) and the work we put into it to keep our marriage God honoring and strong!'
      ],
      img: '../assets/about/certified-mom-brad.jpg'
    },
    {
      name: 'Isaac',
      blocks: [
        `My sweet and pure ${getAge('05/09/2019')} year old son. Isaac is cerebral - he loves to problem solve and fix things! He wants to be an astronaut or a firefighter when he grows up. I feel confident that he will make a big impact on the world with his gift of intelligence!`,
        'He also has a passion for singing, and he is so good at it! Listening to him sing is music to my ears. His precious voice is honey to the soul!'
      ],
      img: '../assets/about/certified-mom-isaac.jpg'
    },
    {
      name: 'Micah',
      blocks: [
        `My FIERCE, strong warrior. Micah has been through more in his ${getAge('04/24/2021')} years of life than some humans go through in a lifetime! There’s a verse in Judges 6 that was written about Gideon, but also describes Micah: “And the angel of the Lord appeared and said to him, ‘The Lord is with you, O mighty man of valor.”`,
        'Micah loves birds, fire trucks, reading books, and dancing. He takes such delight in each of those things!'
      ],
      img: '../assets/about/certified-mom-micah.jpg'
    },
    {
      name: 'Sadie',
      blocks: [
        'We got Sadie 2 months after we got married. Not the best decision we’ve ever made but I can’t imagine life without her! She is my go to when I’m having a bad day. Snuggling on the couch with her is the best! She is obsessed with Brad and giant tennis balls. '
      ],
      img: '../assets/about/certified-mom-sadie.jpg'
    }
  ]

  return (
    <>
      <Divider />
      <Section>
        <Title1>My Family</Title1>
        <Body>
          My family is the BEST part of my life. I love these guys and my pup more than anything!
        </Body>
        <Media
          imageSrc='../assets/about/my-family.jpg'
          aspectRatio={viewportSize === 'compact' ? '4 / 3' : '2 / 1'}
          style={{
            opacity: .8
          }}
        />
        <Space.NewSection />
        {content.map((person) => (
          <>
            <HStack sectionSpacing gridExpanded={[1, 1]} gridCompact={[1]} align='top' gridMedium={[1, 1]}>
              <VStack style={{
                position: viewportSize === 'compact' ? 'static' : 'sticky',
                top: '64px'
              }}>
                <Media
                  imageSrc={person.img}
                  aspectRatio='1 / 1'
                  style={{
                    opacity: .8
                  }}
                />
              </VStack>
              <VStack>
                <Title2 color='accent'>{person.name}</Title2>
                {person.blocks.map((block) => (
                  <Body>{block}</Body>
                ))}
              </VStack>
              <Space.NewSection />
              <Space.NewSection />
            </HStack>
          </>
        ))}
      </Section>
    </>)
}

export default Family

import React from 'react'
import { Section, Title2, Body, HStack, Button } from '@themeable/react-components'
import { setIsFormSheetActive } from '../../Patterns/Navbar'

function Outro({ setGlobals }) {


  const sayHello = () => {
    setGlobals((prev) => ({
      ...prev,
      sayHelloSheet: true
    }));
  }

  return (
    <Section style={{ maxWidth: '900px' }}>
      <Title2 align='left'>
        Thank you for still being with me!
      </Title2>
      <Body>
        I know that was a really long introduction to who we are as well as our purpose behind Certified Mom. I appreciate you investing your precious time here! Your presence is invaluable.
      </Body>
      <Body>
        Please let me know how I can serve you via email or text! You can say hello to me or send me prayer requests, personal questions, professional questions, I want to band together and go on this journey with you!
      </Body>
      <HStack>
        <Button text1='Go to Blog' url='../blog' />
        <Button text1='Say Hello' variant='primary' onClick={() => { sayHello() }} />
      </HStack>
    </Section>
  )
}

export default Outro

import { Button, Container, Footnote, Headline, Icon, HStack, Media, Subhead, VStack, Space } from "@themeable/react"


export const DisclaimerMedical = () => {


  return (
    <Container variant='blank' style={{ backgroundColor: 'rgb(var(--color-primary))' }}>
      <HStack group>
        <Icon name='Warning' color='on-primary' />
        <Space.Content />
        <VStack>
          <Subhead weight='bold' color='on-primary'>Medical disclaimer:</Subhead>
          <Footnote color='on-primary'>
            CertifiedMom.com, owned by Certified Mom, LLC, is not responsible or liable for any advice, course of treatment, diagnosis or any other information, services or products that you obtain through this site. You are encouraged to consult with your doctor with regard to this information contained on or through this website. You can read the full disclaimer here.
          </Footnote>
        </VStack>
      </HStack>
    </Container>
  )
}

export const DisclaimerAffiliate = () => {


  return (
    <Container variant='fill'>
      <HStack group>
        <Icon name='Warning' color='primary' />
        <Space.Content />
        <VStack>
          <Subhead weight='bold' >Disclaimer:</Subhead>
          <Footnote>
            As an Amazon Associate I earn a small commission for purchases you make through links on this page, at no additional cost to you.
          </Footnote>
        </VStack>
      </HStack>
    </Container>
  )
}

import React from 'react'
import { Section, HStack, Title3, VStack, Body, Space, Icon, Subhead, Headline, Caption1, Title2, Container, Caption2, Footnote } from '@themeable/react'

function Certifications() {

  const certifications = [
    {
      icon: 'Nurse',
      title: 'RN',
      subtitle: 'Board of Registered Nursing | Tennessee & California'
    },
    {
      icon: 'Science',
      title: 'BSN',
      subtitle: 'Bachelor Science Nursing | PLNU'
    },
    {
      icon: 'Certified',
      title: 'CPN',
      subtitle: 'Certified Pediatric Nurse'
    },
    {
      icon: 'Fetus',
      title: 'Certified Birth Doula',
      subtitle: 'Childbirth International'
    },
    {
      icon: 'Bottle',
      title: 'Certified Postpartum Doula',
      subtitle: 'Childbirth International'
    },
    {
      icon: 'Breastfeeding',
      title: 'Certified Lactation Consultant',
      subtitle: 'Childbirth International'
    },
  ]

  return (
    <>
      <Section style={{ paddingTop: '0px' }}>
        <Title2 color='--color-accent' align='center'>
          I hold the following licenses/certifications:
        </Title2>
        <HStack gridExpanded={[1, 1, 1, 1, 1, 1]} gridMedium={[1, 1, 1]} gridCompact={[1, 1]}>
          {certifications.map((category) => (
            <VStack group layout='left top'>
              <Container variant='fill'>
                <VStack align='middle center'>
                  <Icon
                    name={category.icon}
                    size='100%'
                    color='primary'
                  />
                </VStack>
              </Container>
              <Space.Content />
              <Headline>{category.title}</Headline>
              <Footnote color='text-secondary'>{category.subtitle}</Footnote >
            </VStack>
          ))}
        </HStack>
      </Section>
    </>
  )
}

export default Certifications

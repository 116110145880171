import React from 'react'
import { Section, Title1, HStack, Image, VStack, Body, Headline, Icon, Space, Media } from '@themeable/react'
import { Logo } from '../../Patterns/Logo'

function Hero() {
  return (
    <Section style={{
      overflow: 'hidden',
      width: '100%'
    }}>
      <HStack
        align='middle'
        gridExpanded={[1, 1]}
        gridMedium={[1, 1]}
        gridCompact={[1]}>

        <VStack style={{
          position: 'relative',
          minHeight: '300px'
        }} layout='center middle'>
          <HStack
            align='middle center'
            style={{
              opacity: '.1',
              position: 'absolute',
              width: '100%',
              scale: '110%',
              height: '100%',
              animation: 'spin 800s linear infinite',
            }}>
            <Icon
              name='PatternFlower'
              size='100%'
              color='accent'
            />
          </HStack>
          <Media
            aspectRatio='1 / 1'
            imageSrc='../assets/about/about-hero.jpg'
            profile
          />
        </VStack>
        <HStack>
          <Space.NewSection showOn='medium expanded' />
          <VStack>
            <Space.NewSection showOn='medium expanded' />
            <Title1 align='left' color='primary'>About</Title1>
            <Body align='left'>
              Hi! I’m Jessica, a Certified Pediatric Nurse (CPN), Birth and Postpartum Doula, and mama to two boys Isaac and Micah. Certified Mom is my place to serve you with THE best mom and baby tips. I cover everything from pregnancy to postpartum and beyond.
            </Body>
            <Body>
              If you’re in South Orange County, CA looking for doula support during pregnancy, birth, postpartum, and breastfeeding, you can learn more! I would LOVE to be on your team!
            </Body>
            <Space.NewSection showOn='medium expanded' />
          </VStack>

        </HStack>

      </HStack>
      <Space.NewSection />
      <Space.NewSection />
    </Section>
  )
}

export default Hero

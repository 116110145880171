import { Icon, VStack, HStack, Section, Headline, Subhead, Title3, Space } from '@themeable/react'
import React from 'react'

function Quote() {
  return (
    <>
      <Section>
        <HStack align='center' style={{ position: 'relative' }}>
          <VStack style={{ position: 'absolute', width: '100%', height: '100%', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
            <Icon
              name='PatternBackground'
              color='accent'
              size='100%'
            />
          </VStack>
          <VStack style={{ maxWidth: '600px', zIndex: 2 }} >
            <Space.NewSection />
            <Title3 align='center'>
              “I want you to know there's nothing that you can’t do. You’ve already shown that with the toughest job in the world...you’re a mother. You embody what it means to be selfless and resilient. You are what the world needs.”
            </Title3>
            <VStack group>
              <Subhead align='center'>Co-Founder of Tone It Up</Subhead>
              <Headline align='center'>Katrina Scott</Headline>
            </VStack>
            <Space.NewSection />
          </VStack>
        </HStack>
      </Section>

      {/* Illustration */}
      <VStack style={{ position: 'relative' }} showOn='medium expanded'>
        <VStack style={{ position: 'absolute', top: '50%', right: '0px', transform: 'translate(-20%, -100%) rotate(0deg)' }}>
          <Icon
            name='Mom'
            size='200px'
            color='primary'
          />
        </VStack>
      </VStack>


    </>
  )
}

export { Quote }
